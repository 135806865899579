import React from "react"
import ReactPlayer from "react-player"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/pagination"

import { FreeMode, Pagination } from "swiper"
import playIcon from "../../images/icons/play.png"
import playHoverIcon from "../../images/icons/Play-hover.png"

const VideoSlider = props => {
  return (
    <div>
      <>
        <Swiper
          slidesPerView={2.4}
          spaceBetween={20}
          freeMode={true}
          breakpoints={{
            1536: {
              slidesPerView: 2.4,
            },
            1280: {
              slidesPerView: 2.4,
            },
            1024: {
              slidesPerView: 2.4,
            },
            768: {
              slidesPerView: 1,
            },
            0: {
              slidesPerView: 1,
            },
          }}
          pagination={{
            el: ".my-custom-pagination-div",
            clickable: true,
            renderBullet: (index, className) => {
              return '<span class="' + className + '"> </span>'
            },
          }}
          grabCursor={true}
          modules={[FreeMode, Pagination]}
          className="mySwiper"
        >
          {props.slides.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="rounded-[30px] group h-[200px] lg:h-[250px] xl:h-[355px]">
                  <ReactPlayer
                    url={item.videoURL}
                    controls={true}
                    muted={true}
                    width="100%"
                    height="100%"
                    playsinline
                    playIcon={
                      <>
                        <img
                          src={playIcon}
                          className="group-hover:hidden"
                          width={80}
                        />
                        <img
                          src={playHoverIcon}
                          className="hidden group-hover:block"
                          width={60}
                        />
                      </>
                    }
                    loop
                    className="rounded-[30px]"
                    light={item.lightURL}
                  />
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </>
    </div>
  )
}

export default VideoSlider
