import React, { useEffect, useState } from "react"
import ReactPlayer from "react-player"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import VideoSlider from "../components/sliders/video-slider"
import VideoSliderSecond from "../components/sliders/video-slider-duplicate"
import BlogSlider from "../components/sliders/video-slider-three-col"
import {
  ArrowRightIcon,
  ArrowLongRightIcon,
  XMarkIcon,
  PlayIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid"
import Map from "../components/map"
import Gallery from "../components/gallery"

const SadahamSathkara = ({ data }) => {
  const targetDate = "2023-06-02T12:00:00"
  const [visible, setVisible] = useState(8)
  const [popUp, setPopUp] = useState(false)
  const [active, setActive] = useState()
  const [month, setMonth] = useState(true)

  const images = {
    header: convertToBgImage(getImage(data.header)),
    mobile_bg: convertToBgImage(getImage(data.mobile_bg)),

    icon: getImage(data.icon),
    icon_1: getImage(data.icon_1),

    image1: getImage(data.image1),
    image2: getImage(data.image2),
    image3: getImage(data.image3),
    image4: getImage(data.image4),
    image5: getImage(data.image5),
    image6: getImage(data.image6),
    image7: getImage(data.image7),
    image8: getImage(data.image8),
    image9: getImage(data.image9),
    image10: getImage(data.image10),
    image11: getImage(data.image11),
    image12: getImage(data.image12),
    image13: getImage(data.image13),
    image14: getImage(data.image14),
    image15: getImage(data.image15),
    image16: getImage(data.image16),
    image17: getImage(data.image17),
    image18: getImage(data.image18),
    image19: getImage(data.image19),
    image20: getImage(data.image20),
  }

  const advantages = [
    {
      icon: images.icon,
      name: "S Muthukumarana",
      icon_1: images.icon_1,
      description:
        "අතිශයින් ම වැදගත් විශාල පුණ්ය කර්මයක් ලැබෙනව තෙරුවන් සරණයි සුවපත් වෙත්වා අධිෂ්ඨාන කරමි",
    },
    {
      icon: images.icon,
      name: "Janaka Gunarathne",
      icon_1: images.icon_1,
      description:
        "මිහින්තලේ වටවල තේ දන්සල ගැන නම් කියන්න වචන මදි හොදටම මහන්සි වෙලා පුද බිමට එද්දි දුන්න තේ එක අමුර්ථය දුන්නා වගේ වටිනවා",
    },
    {
      icon: images.icon,
      name: "WD Ariaysena",
      icon_1: images.icon_1,
      description:
        "වටවල තේ පැන් සත්කාරවලට අමතරව තාක්ෂණය හරහා නවමු අත්දැකීමක් ලබාදීම පිලිබද ස්තුතියි. ලංකා බැංකුවෙ විශ්‍රාමික සංගමය පන්නිපිටිය ශාඛාව වෙනුවෙන්.",
    },
    {
      icon: images.icon,
      name: "Deshani Sathsarani",
      icon_1: images.icon_1,
      description:
        "ඔයාල හදපු තේ බිව්වා මිහින්තලේදි. තිබහෙ හිටියෙ. හරිම රසයි. ගොඩක් පින්.",
    },
    {
      icon: images.icon,
      name: "Wajira Wijekoon",
      icon_1: images.icon_1,
      description: "මෙත් සිත් වඩන අමා දහම් සිසිල බෙදදන වටවල යහමින් වැජබේවා ",
    },
    {
      icon: images.icon,
      name: "Sany Rathnasena",
      icon_1: images.icon_1,
      description:
        "ඉතිහාසගත තොරතුරැ ඉදිරිපත් කිරීම ගැන ස්තුතියි. තෙරැවන් සරණයි.",
    },
  ]

  const gallery = [
    {
      image: images.image1,
    },
    {
      image: images.image2,
    },
    {
      image: images.image3,
    },
    {
      image: images.image4,
    },
    {
      image: images.image5,
    },
    {
      image: images.image6,
    },
    {
      image: images.image7,
    },
    {
      image: images.image8,
    },
    {
      image: images.image9,
    },
    {
      image: images.image10,
    },
    {
      image: images.image11,
    },
    {
      image: images.image12,
    },
    {
      image: images.image13,
    },
    {
      image: images.image14,
    },
    {
      image: images.image15,
    },
    {
      image: images.image16,
    },
    {
      image: images.image17,
    },
    {
      image: images.image18,
    },
    {
      image: images.image19,
    },
    {
      image: images.image20,
    },
  ]

  return (
    <>
      <Layout>
        <Seo title="සදහම් සත්කාර  | Watawala" />
        <section>
          <div className="">
            <BackgroundImage
              {...images.header}
              className="  responsive-header relative bg-no-repeat  "
              style={{ backgroundPosition: "left bottom" }}
            >
              {/* <div className="absolute right-0 bottom-0  ">
                <StaticImage
                  src="../images/pages/sadaham-sathkara/art.png"
                  class="w-full h-full bg-cover"
                  alt="watawala"
                />
              </div> */}
              <div className=" h-full ">
                <div className="container mx-auto h-full md:px-20 px-10 ">
                  <div className="h-full  relative">
                    <div className="flex items-center justify-start h-full ">
                      <div>
                        <div className="flex lg:justify-start justify-center md:mt-0 mt-10">
                          <StaticImage
                            src="../images/pages/sadaham-sathkara/title.png"
                            className="lg:w-1/4 w-1/2"
                            alt="watawala"
                          />
                        </div>

                        <div className="lg:text-left text-center">
                          <div className="font-bold md:text-6xl text-5xl mt-10">
                            වටවල සදහම් සත්කාර
                          </div>
                          <div className=" max-w-sm mt-10 font-light text-lg lg:mx-0 mx-auto ">
                            පොසොන් පෝයට මිහින්තලේ යන්න බැරි වුන ඔබට, ගෙදර ඉඳන්ම
                            මිහින්තලේ ගැන ඉගෙනගන්න බොහෝ දේ මේ තුළ තියෙනවා. මේ
                            පාර මිහින්තලේ යන්න බැරි උනාට, මේ වෙබ් පිටුව පරිශීලනය
                            කරපු ඔබට පස්සේ කාලෙක මිහින්තලේ යද්දි දරුවන්ට මේ ගැන
                            මතක් කරලා කියලා දෙන්නත් පුළුවන්.
                          </div>
                          <div className="flex gap-4 mt-10 lg:justify-start md:justify-center justify-center">
                            {/* <Link>
                              <div>
                                {" "}
                                <StaticImage
                                  src="../images/pages/sadaham-sathkara/app1.png"
                                  className="w-40"
                                  alt="watawala"
                                />
                              </div>
                            </Link> */}

                            <div>
                              <a href="https://play.google.com/store/apps/details?id=com.watawala.app">
                                <StaticImage
                                  src="../images/pages/sadaham-sathkara/app2.png"
                                  className="w-40"
                                  alt="watawala"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </BackgroundImage>
          </div>
        </section>
        {/* <section>
          <div className="relative">
            <StaticImage
              src="../images/pages/sadaham-sathkara/header.png"
              className="bg-cover h-full w-full"
            />
            <div className="absolute inset-0 flex flex-col items-center">
              <div className=" h-full ">
                <div className="container mx-auto h-full md:px-20 px-10 ">
                  <div className="flex xl:flex-row flex-col  items-center  justify-center xl:justify-between h-full  relative">
                    <div className="flex  justify-center ">
                      <div>
                        <div className="flex lg:justify-start justify-center md:mt-0 mt-10">
                          <StaticImage
                            src="../images/pages/sadaham-sathkara/title.png"
                            className="lg:w-1/3 w-1/2"
                            alt="watawala"
                          />
                        </div>

                        <Countdown targetDate={targetDate} />

                        <div className="lg:text-left text-center">
                          <div className="font-bold md:text-6xl text-5xl mt-10">
                            වටවල සදහම් සත්කාර
                          </div>
                          <div className=" max-w-sm mt-10 font-light text-lg lg:mx-0 mx-auto ">
                            මිහින්තලේ වඳින්න එන ඔබට වටවල සදහම් සත්කාර වෙතින්
                            පිදෙන අපූරු තිළිණය.ඔබත් පෙර නොතිබුණු මේ අත්දැකීම ගැන
                            දැනගන්න බලාපොරොත්තුවෙන් ඉන්න.
                          </div>npm start
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section>
          <div className="container mx-auto px-10 py-20">
            <div>
              <div className="flex justify-center">
                <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                  ව්‍යාපෘතිය
                </div>
              </div>
              <div className="uppercase font-bold text-5xl text-center mx-auto mb-4 tracking-widest">
                වටවල සදහම් සත්කාර
                <br /> පොසොන් වැඩසටහන
              </div>
              <div className="max-w-xl text-center mb-7 mx-auto font-light text-lg">
                වටවල සදහම් සත්කාර පොසොන් ව්‍යාපෘතිය අන්තර්ගතය ගැන දැනගන්න පහත
                වීඩියෝ නරඹන්න.
              </div>
            </div>

            <div className="testomonial">
              <div className="rounded-2xl overflow-hidden lg:w-full grid lg:grid-cols-2 grid-cols-1 gap-5 mx-auto">
                <div
                  className="relative pb-[100%]"
                  style={{ paddingBottom: "100%" }}
                >
                  <ReactPlayer
                    url="https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/video.mp4?alt=media&token=d90b85c6-f743-4332-b814-8d7b1b29581d"
                    controls={true}
                    playing={true}
                    muted={false}
                    width="100%"
                    height="100%"
                    className="absolute top-0 left-0 w-full h-full"
                    playIcon={
                      <button className="p-8 rounded-full hover:bg-yellow duration-200 bg-white">
                        <PlayIcon className="w-12 text-brown" />
                      </button>
                    }
                    light="https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/Screenshot%202023-06-02%20214450.png?alt=media&token=934951da-6d1d-478b-a02e-af7430a6f8fa"
                  />
                </div>
                <div
                  className="relative pb-[100%]"
                  style={{ paddingBottom: "100%" }}
                >
                  <ReactPlayer
                    url="https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/video_1.mp4?alt=media&token=55162e46-c901-490c-8332-9605886d3a22"
                    controls={true}
                    playing={true}
                    muted={false}
                    width="100%"
                    height="100%"
                    className="absolute top-0 left-0 w-full h-full"
                    playIcon={
                      <button className="p-8 rounded-full hover:bg-yellow duration-200 bg-white">
                        <PlayIcon className="w-12 text-brown" />
                      </button>
                    }
                    light="https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/Screenshot%202023-06-02%20214801.png?alt=media&token=76b741ee-4a8e-4660-8b44-8e10316814c7"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="">
            <Map />
          </div>
        </section>
        {/* <section>
          <di className="h-full">
            <div className="container mx-auto  py-20 h-full bg-yellowshade ">
              <div className="flex justify-center ">
                <div>
                  <div className="flex justify-center">
                    <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                      ඡායාරූප
                    </div>
                  </div>
                  <div className="uppercase font-bold text-3xl text-center  mx-auto mb-4 tracking-widest">
                    වටවල සදහම් සත්කාර ව්‍යාපෘතියේ ඡායාරූප
                  </div>
                </div>
              </div>
              <div>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  {gallery.slice(0, visible).map((item, index) => {
                    return (
                      <div key={index}>
                        <div
                          onClick={() => {
                            setPopUp("true")
                            setActive(index)
                          }}
                        >
                          <GatsbyImage
                            alt="Watawala"
                            image={item.image}
                            className="cursor-pointer rounded-xl"
                          />
                        </div>
                        {popUp && (
                          <div className="relative">
                            <div className="bg-black/10 fixed z-50 top-0 left-0 w-full h-screen">
                              <XMarkIcon
                                className="absolute top-0 m-5 right-0 z-40 w-8 text-white cursor-pointer"
                                onClick={() => setPopUp(false)}
                              />
                              <div className="flex items-center justify-center  h-screen">
                                <GatsbyImage
                                  alt="Watawala"
                                  image={gallery[active].image}
                                  className="w-1/2"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="flex justify-center mt-7">
                <button
                  className=" tracking-wide uppercase border border-black font-light rounded-full px-6 py-2 hover:bg-yellow hover:border-yellow duration-200"
                  onClick={() => {
                    setVisible(visible + 4)
                  }}
                >
                  <div className="">තවත් බලන්න</div>
                </button>
              </div>
            </div>
          </di>
        </section> */}

        <section>
          <div className="h-full">
            <div className=" py-20 h-full bg-yellowshade ">
              <div className="container mx-auto ">
                <div className="flex justify-center ">
                  <div>
                    <div className="flex justify-center">
                      <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                        ඡායාරූප
                      </div>
                    </div>
                    <div className="uppercase font-bold text-3xl text-center  mx-auto mb-4 tracking-widest">
                      වටවල සදහම් සත්කාර ව්‍යාපෘතියේ ඡායාරූප
                    </div>
                  </div>
                </div>
                <div>
                  <div className="grid grid-cols-1 md:grid-cols-2 mt-2 lg:grid-cols-3 gap-4 px-10">
                    {gallery.slice(0, visible).map((item, index) => {
                      return (
                        <div key={index}>
                          <div
                            onClick={() => {
                              setPopUp("true")
                              setActive(index)
                            }}
                          >
                            {/* <GatsbyImage
                            alt="Watawala"
                            image={getImage(
                              data.allFile.nodes.find(
                                node => node.name === item.image
                              )
                            )}
                            className="cursor-pointer rounded-xl"
                          /> */}

                            <GatsbyImage
                              image={item.image}
                              className="cursor-pointer rounded-xl"
                              alt="Watawala"
                            />
                          </div>
                          {popUp && (
                            <div className="relative">
                              <div className="bg-black/10 fixed z-50 top-0 left-0 w-full h-screen">
                                <XMarkIcon
                                  className="absolute top-0 m-5 right-0 z-40 w-8 text-white cursor-pointer"
                                  onClick={() => setPopUp(false)}
                                />
                                <div className="flex items-center justify-center  h-screen">
                                  <GatsbyImage
                                    alt="Watawala"
                                    image={gallery[active].image}
                                    className="w-1/2"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="flex justify-center mt-7">
                  <button
                    className=" tracking-wide uppercase border border-black font-light rounded-full px-6 py-2 hover:bg-yellow hover:border-yellow duration-200"
                    onClick={() => {
                      setVisible(visible + 4)
                    }}
                  >
                    <div className="">තවත් බලන්න</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container py-20 px-10 mx-auto ">
            <div className="flex justify-center">
              <div>
                <div className="flex justify-center">
                  <div className="px-4 py-1 uppercase mb-4 bg-yellow/40 align-content rounded-full text-brown font-thin">
                    අදහස්
                  </div>
                </div>
                <div className="uppercase font-bold text-4xl text-center mx-auto mb-4 tracking-widest">
                  බැතිමතුන්ගේ අදහස් හා යෝජනා
                </div>
              </div>
            </div>
            <div className="max-w-xl text-center mb-4 mx-auto font-light text-lg">
              වටවල සදහම් සත්කාර වැඩසටහන් සමග එකතු වූ බැතිමතුන්ගේ අදහස් හා යෝජනා
              පහතින් කියවන්න
            </div>
            <div className="">
              <div className="grid lg:grid-cols-2 xl:grid-cols-3 w-full p-2 gap-8 justify-center">
                {advantages.map((item, index) => {
                  return (
                    <div
                      className="bg-white  shadow-lg p-8  rounded-xl "
                      key={index}
                    >
                      <div className="text-center">
                        <div className="rounded-full  ">
                          <div className="  flex w-full items-center  justify-between ">
                            <div className="flex  gap-5 items-center">
                              <GatsbyImage
                                src="Watawala"
                                image={item.icon}
                                className="w-12"
                                alt="Watawala"
                              />
                              <div className="font-medium text-lg">
                                {item.name}
                              </div>
                            </div>
                            <div className="flex">
                              <GatsbyImage
                                src="Watawala"
                                image={item.icon_1}
                                className="w-6"
                                alt="Watawala"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="font-light mt-4  text-[#707070] text-lg text-left">
                        {item.description}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default SadahamSathkara
export const query = graphql`
  query SadahamSathkaraImages {
    header: file(relativePath: { eq: "pages/sadaham-sathkara/header.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    mobile_bg: file(
      relativePath: { eq: "pages/sadaham-sathkara/mobile_bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_1: file(
      relativePath: { eq: "pages/sadaham-sathkara/gallery_1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    gallery_2: file(
      relativePath: { eq: "pages/sadaham-sathkara/gallery_2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    gallery_3: file(
      relativePath: { eq: "pages/sadaham-sathkara/gallery_3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_4: file(
      relativePath: { eq: "pages/sadaham-sathkara/gallery_4.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_5: file(
      relativePath: { eq: "pages/sadaham-sathkara/gallery_5.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_6: file(
      relativePath: { eq: "pages/sadaham-sathkara/gallery_6.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_7: file(
      relativePath: { eq: "pages/sadaham-sathkara/gallery_7.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    icon: file(relativePath: { eq: "pages/sadaham-sathkara/icon.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    icon_1: file(relativePath: { eq: "pages/sadaham-sathkara/icon_1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    image1: file(relativePath: { eq: "pages/sadaham-sathkara/image1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    image2: file(relativePath: { eq: "pages/sadaham-sathkara/image2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    image3: file(relativePath: { eq: "pages/sadaham-sathkara/image3.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    image4: file(relativePath: { eq: "pages/sadaham-sathkara/image4.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    image5: file(relativePath: { eq: "pages/sadaham-sathkara/image5.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    image5: file(relativePath: { eq: "pages/sadaham-sathkara/image5.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    image6: file(relativePath: { eq: "pages/sadaham-sathkara/image6.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    image7: file(relativePath: { eq: "pages/sadaham-sathkara/image7.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    image8: file(relativePath: { eq: "pages/sadaham-sathkara/image8.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    image9: file(relativePath: { eq: "pages/sadaham-sathkara/image9.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    image10: file(relativePath: { eq: "pages/sadaham-sathkara/image10.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    image11: file(relativePath: { eq: "pages/sadaham-sathkara/image11.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    image12: file(relativePath: { eq: "pages/sadaham-sathkara/image12.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    image13: file(relativePath: { eq: "pages/sadaham-sathkara/image13.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    image14: file(relativePath: { eq: "pages/sadaham-sathkara/image14.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    image15: file(relativePath: { eq: "pages/sadaham-sathkara/image15.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    image16: file(relativePath: { eq: "pages/sadaham-sathkara/image16.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    image17: file(relativePath: { eq: "pages/sadaham-sathkara/image17.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    image18: file(relativePath: { eq: "pages/sadaham-sathkara/image18.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    image19: file(relativePath: { eq: "pages/sadaham-sathkara/image19.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`
