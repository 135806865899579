import React from "react"
import ReactPlayer from "react-player"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/pagination"

import { FreeMode, Pagination } from "swiper"
import { GatsbyImage } from "gatsby-plugin-image"

const VideoSliderSecond = props => {
  return (
    <div>
      <>
        <Swiper
          slidesPerView={2.4}
          spaceBetween={20}
          freeMode={true}
          pagination={{
            el: ".my-custom-pagination-div-2",
            clickable: true,
            renderBullet: (index, className) => {
              return '<span class="' + className + '"> </span>'
            },
          }}
          breakpoints={{
            1536: {
              slidesPerView: 2.4,
            },
            1280: {
              slidesPerView: 2.4,
            },
            1024: {
              slidesPerView: 2.4,
            },
            768: {
              slidesPerView: 1,
            },
            0: {
              slidesPerView: 1,
            },
          }}
          grabCursor={true}
          modules={[FreeMode, Pagination]}
          className="mySwiper"
        >
          {props.slides.map((item, index) => {
            return (
              <div className="overflow-hidden" key={index}>
                <SwiperSlide>
                  <GatsbyImage
                    image={item.image}
                    alt="Watawala"
                    className="rounded-3xl"
                  />
                </SwiperSlide>
              </div>
            )
          })}
        </Swiper>
      </>
    </div>
  )
}

export default VideoSliderSecond
